import {useEffect, useState} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress} from "@mui/material";
import {
  CheckRounded,
  ExpandMore, HowToRegRounded, PhishingOutlined,
  PhishingRounded,
  QuestionMark,
  TimerOutlined,
  WarningOutlined,
  WarningRounded
} from "@mui/icons-material";
import {
  collection,
  deleteDoc,
  doc,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc
} from "firebase/firestore";
import dayjs from "dayjs";

import * as auth from '../../firebase/auth.js';
import {getFunctions, httpsCallable} from "firebase/functions";
import {deleteObject, getDownloadURL, getStorage, ref} from "firebase/storage";
import LoadingIndicator from "../LoadingIndicator";
import PenaltyAdder from "./PenaltyAdder";

const formatNumber = (number) => {
  number *= 100;
  number = Math.round(number);
  number /= 100;
  return number + '€';
};

const formatDate = (millis) => {
  const date = dayjs(millis);
  return date.format('DD.MM.YYYY');
}

const getPenaltyStatusIcon = (status) => {
  switch (status) {
    case 'pending-own':
      return (<WarningRounded sx={{fill: getPenaltyStatusColor(status)}}/>);
    case 'approved-own':
      return (<PhishingRounded sx={{fill: getPenaltyStatusColor(status)}}/>);
    case 'pending':
      return (<TimerOutlined sx={{fill: getPenaltyStatusColor(status)}}/>);
    case 'approved':
      return (<HowToRegRounded sx={{fill: getPenaltyStatusColor(status)}}/>);
    default:
      return (<QuestionMark sx={{fill: getPenaltyStatusColor(status)}}/>);
  }
}

const getPenaltyStatusColor = (status) => {
  switch (status) {
    case 'pending-own':
      return 'red';
    case 'pending':
      return 'yellow';
    default:
      return 'white';
  }
}

function PenaltyView() {
  const firestore = getFirestore();
  const storage = getStorage();
  const [penalties, setPenalties] = useState(null);
  const [expanded, setExpanded] = useState('');
  const [loading, setLoading] = useState('');
  const [imageUrls, setImageUrls] = useState({});

  const getStorageRef = (penalty) => {
    return ref(storage, 'penalties/' + penalty.uid);
  }

  const updateImageUrl = async (penalty) => {
    if(Object.keys(imageUrls).includes(penalty.uid)) {
      return;
    }
    imageUrls[penalty.uid] = null;
    try {
      imageUrls[penalty.uid] = await getDownloadURL(getStorageRef(penalty));
      setImageUrls(JSON.parse(JSON.stringify(imageUrls)));
    } catch (e) {}
  }

  const deletePenalty = async (penalty) => {
    setLoading(penalty.uid + '-delete');
    const docRef = doc(firestore, 'penalties/' + penalty.uid);
    await deleteDoc(docRef);
    try {
      await deleteObject(getStorageRef(penalty));
    } catch (e) {}
    setLoading('');
  };

  const approvePenalty = async (penalty) => {
    setLoading(penalty.uid + '-approve');
    const transactionData = {
      userId: penalty.to.uid,
      amount: Math.abs(penalty.amount) * -1,
      kind: 'penalty',
      details: 'Einhängung von ' + penalty.from.name + ' (' + formatDate(penalty.time) + ')',
    };
    const functions = getFunctions();
    const executeTransaction = httpsCallable(functions, 'executeTransaction');
    const response = await executeTransaction(transactionData);
    if(response.error) {
      alert(response.error);
      setLoading('');
      return;
    }
    const docRef = doc(firestore, 'penalties/' + penalty.uid);
    const dataUpdate = {
      'status': 'approved'
    }
    await updateDoc(docRef, dataUpdate);
    setLoading('');
  };

  useEffect(() => {
    if(window.penaltyDataUnsubscribe) {
      window.penaltyDataUnsubscribe();
    }
    const queryRef = query(collection(firestore, 'penalties'), orderBy('time', 'desc'), limit(100));
    window.penaltyDataUnsubscribe = onSnapshot(queryRef, (querySnapshot) => {
      let newPenalties = querySnapshot.docs.map(docSnapshot => {
        const data = docSnapshot.data();
        data.uid = docSnapshot.id;
        return data;
      });
      newPenalties = newPenalties.map(penalty => {
        if(penalty.to.uid === auth.CURRENT_USER.uid) {
          penalty.status += '-own';
          penalty.to.name = 'DICH';
        }
        return penalty;
      });
      setPenalties(newPenalties);
    });
  }, []);

  if(!penalties) {
    return (<LoadingIndicator/>);
  }

  return (
    <Box>
      <PenaltyAdder/>
      {penalties.map(penalty => {
        return (
          <Accordion key={penalty.uid} expanded={penalty.uid === expanded} onClick={() => {
            if(expanded === penalty.uid) {
              setExpanded('');
            } else {
              setExpanded(penalty.uid);
              updateImageUrl(penalty);
            }
          }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              {getPenaltyStatusIcon(penalty.status)}
              <Box sx={{display: 'inline-block', marginLeft: 1, fontWeight: 500}}>{penalty.from.name + (penalty.status.startsWith('approved') ? ' hat ' : ' will ') + penalty.to.name + ' für ' + formatNumber(penalty.amount) + (penalty.status.startsWith('approved') ? ' eingehangen' : ' einhängen')}</Box><Box sx={{marginLeft: 'auto'}}>{formatDate(penalty.time)}</Box>
            </AccordionSummary>
            <AccordionDetails>
              {!!imageUrls[penalty.uid] && <Box sx={{marginBottom: 1, maxWidth: '100%', maxHeight: '300px'}}>
                <img style={{objectFit: 'contain', maxWidth: '100%', maxHeight: '300px'}} src={imageUrls[penalty.uid]} alt={'media'}/>
              </Box>}
              <Box sx={{marginBottom: 1}}>
                <Box sx={{display: 'inline-block', fontWeight: 600}}>Begründung:</Box> <Box sx={{display: 'inline-block'}}>{penalty.reason}</Box>
              </Box>
              <Box sx={{marginBottom: 1}}>
                {penalty.status.startsWith('pending') && auth.hasTag('penalties.admin') &&
                <Button variant='outlined' color='primary' sx={{mr: 1, height: '40px', width: '150px'}} disabled={loading !== ''} onClick={(event) => {
                  event.stopPropagation();
                  approvePenalty(penalty);
                }}>
                  {(loading === penalty.uid + '-approve') ? (<CircularProgress size={30}/>) : 'Bestätigen'}
                </Button>}
                {penalty.status.startsWith('pending') && (auth.hasTag('penalties.admin') || auth.CURRENT_USER.uid === penalty.from.uid) &&
                <Button variant='outlined' color='error' sx={{mr: 1, height: '40px', width: '150px'}} disabled={loading !== ''} onClick={(event) => {
                  event.stopPropagation();
                  deletePenalty(penalty);
                }}>
                  {(loading === penalty.uid + '-delete') ? (<CircularProgress size={30}/>) : 'Löschen'}
                </Button>}
              </Box>
              {penalty.status === 'pending-own' && <Box sx={{fontSize: 13, color: 'grey'}}>
                Falls du diese Einhängung nicht gerechtfertigt findest, kontaktiere bitte {penalty.from.name} oder spreche diese Einhängung beim nächsten Konvent an.
              </Box>}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}

export default PenaltyView;