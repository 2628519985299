import {Box} from "@mui/material";
import BottomNavigationBar from "./BottomNavigationBar";
import {Outlet} from "react-router";

function HomeMenu() {
  return (
    <Box>
      <Box className={'hideScrollbar'} sx={{
              height: '90dvh',
              width: '100dvw',
              maxWidth: '800px',
              position: 'fixed',
              overflow: 'auto',
              overflowX: 'hidden',
              top: 0,
          }}>
        <Outlet/>
      </Box>
      <Box sx={{
              height: '10dvh',
              width: '100%',
              maxWidth: '800px',
              position: 'fixed',
              bottom: 0,
          }}>
        <BottomNavigationBar/>
      </Box>
    </Box>
  );
}

export default HomeMenu;
