import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";

import * as auth from '../../firebase/auth.js';
import {useEffect, useState} from "react";
import {getFirestore, doc, getDoc} from "firebase/firestore";
import dayjs from "dayjs";
import {PieChart} from "@mui/x-charts";
import {useNavigate} from "react-router-dom";

const MILLIS_IN_A_WEEK = 1000 * 60 * 60 * 24 * 7;

function FencingPage() {
  const navigate = useNavigate();
  const firestore = getFirestore();

  const [fencingUser, setFencingUser] = useState(auth.ALL_USER.filter(user => user.tags && user.tags.includes('fencing')));
  const [fencingStats, setFencingStats] = useState({});

  useEffect(() => {
    const execute = async () => {
      for(const user of fencingUser) {
        const docRef = doc(firestore, 'user/' + user.uid + '/fencing/stats');
        fencingStats[user.uid] = getDoc(docRef);
      }
      for(const user of fencingUser) {
        fencingStats[user.uid] = (await fencingStats[user.uid]).data();
        if(!fencingStats[user.uid]) {
          delete fencingStats[user.uid];
          continue;
        }
        fencingStats[user.uid].total = fencingStats[user.uid].A + fencingStats[user.uid].E + fencingStats[user.uid].F;
        fencingStats[user.uid].attendance = fencingStats[user.uid].A / fencingStats[user.uid].total;
        fencingStats[user.uid].firstDate = dayjs(fencingStats[user.uid].firstTime).format('DD.MM.YYYY');
        fencingStats[user.uid].color = 'green';
        if(fencingStats[user.uid].attendance < 0.35) {
          fencingStats[user.uid].color = 'red';
        } else if(fencingStats[user.uid].attendance < 0.5 ) {
          fencingStats[user.uid].color = 'orangered';
        }else if(fencingStats[user.uid].attendance < 0.65 ) {
          fencingStats[user.uid].color = 'orange';
        }else if(fencingStats[user.uid].attendance < 0.8 ) {
          fencingStats[user.uid].color = 'yellowgreen';
        }
        fencingStats[user.uid].perWeek = Math.round(fencingStats[user.uid].A / Math.ceil((Date.now() - fencingStats[user.uid].firstTime) / MILLIS_IN_A_WEEK) * 10) / 10;
      }

      let newFencingUser = Object.keys(fencingStats);
      newFencingUser = newFencingUser.sort((a, b) => {
        if(a === auth.CURRENT_USER.uid) {
          return 1;
        } else if (b === auth.CURRENT_USER.uid) {
          return -1;
        }
        return fencingStats[a].attendance - fencingStats[b].attendance;
      });
      newFencingUser = newFencingUser.reverse();
      setFencingUser(newFencingUser);

      setFencingStats(JSON.parse(JSON.stringify(fencingStats)));
    }
    execute();
  }, []);

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column'
    }}>
      {auth.hasTag('fencing.admin') && <Button variant='outlined' sx={{margin: 1}} onClick={() => navigate('/home/fencing/admin')}>
        Zur Fechtwart Ansicht
      </Button>}
      <Box sx={{
        fontSize: 25,
        fontWeight: 600,
        textAlign: 'center',
        marginBottom: '5px',
        marginTop: '10px',
      }}>
        Paukstatistiken
      </Box>
      {fencingStats && fencingUser.map(userId => {
        const stats = fencingStats[userId];
        const user = auth.ALL_USER.filter(u => u.uid === userId)[0];
        if(!stats || !user) {
          return <div key={Math.random()}></div>
        }
        const chartData = [
          { value: stats.A, label: 'Anwesend', color: 'green' },
          { value: stats.E, label: 'Entschuldigt', color: 'cyan' },
          { value: stats.F, label: 'Fehlend', color: 'red' },
        ]
        return (
          <Accordion key={userId} defaultExpanded={userId === auth.CURRENT_USER.uid}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Box>{user.firstname} {user.lastname} (<Box color={stats.color} sx={{display: 'inline-block'}}>{Math.round(stats.attendance * 100)}%</Box>)</Box>
            </AccordionSummary>
            <AccordionDetails>
              <PieChart
                series={[
                  {
                    data: chartData,
                    innerRadius: 30,
                    outerRadius: 60,
                    paddingAngle: 5,
                    cornerRadius: 5,
                    cx: 70,
                  },
                ]}
                width={320}
                height={150}
              />
              <Typography sx={{marginTop: 2}}>
                Paukt {stats.perWeek}x pro Woche
              </Typography>
              <Typography>
                Insgesammt {stats.A} Paukstunden
              </Typography>
              <Typography>
                Paukt seit dem {stats.firstDate}
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}

export default FencingPage;
