import {DateCalendar} from "@mui/x-date-pickers";
import {Box} from "@mui/material";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";

import * as auth from '../../firebase/auth.js';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import {getFirestore, doc, getDoc, setDoc} from "firebase/firestore";

const ATTEND_STATE_COLOR = {
  ' ': 'grey',
  'A': 'green',
  'E': 'blue',
  'F': 'red',
}
const TIMOUT_BEFORE_UPDATE = 2 * 1000; // 2 Seconds

const timeoutsForUpdate = {}; // date: timeoutID

function FencingAdminAttendanceCalendar() {
  const [attendance, setAttendance] = useState({});
  const [date, setDate] = useState(dayjs());

  const firestore = getFirestore();

  const refreshAttendance = async (newDate) => {
    const docRef = doc(firestore, 'fencing', newDate.format('DD.MM.YYYY'));
    const data = (await getDoc(docRef)).data();
    if(!data) {
      setAttendance({});
      return;
    }
    setAttendance(data.attendance);
  };

  useEffect(() => {
    refreshAttendance(date);
  }, []);

  const updateAttendance = async () => {
    const currentDateFormatted = date.format('DD.MM.YYYY');
    const currentTimeMillis = date.valueOf();
    const currentAttendance = JSON.parse(JSON.stringify(attendance));

    const execute = async () => {
      const docRef = doc(firestore, 'fencing', currentDateFormatted);
      await setDoc(docRef, {
        time: currentTimeMillis,
        attendance: currentAttendance,
      });
    }

    const oldTimeout = timeoutsForUpdate[currentDateFormatted];
    if(oldTimeout) {
      window.clearTimeout(oldTimeout);
    }
    timeoutsForUpdate[currentDateFormatted] = window.setTimeout(() => execute(), TIMOUT_BEFORE_UPDATE);
  };


  return (
    <Box sx={{display: 'flex', justifyContent: 'center'}}>
      <DateCalendar sx={{margin: 0, width: '60dvw', maxWidth: '300px'}} value={date} onChange={value => {
        setDate(value);
        refreshAttendance(value);
      }}/>
      <Box>
        <Paper sx={{ width: '40dvw', maxWidth: 300, height: 275, overflow: 'auto' }}>
          <List dense component="div" role="list">
            {auth.ALL_USER.filter(user => user.tags && user.tags.includes('fencing')).map((user) => {
              const labelId = `transfer-list-item-${user.uid}-label`;
              const attendingState = attendance[user.uid] || ' ';
              const color = ATTEND_STATE_COLOR[attendingState];
              const nextState = () => {
                let newState;
                switch (attendingState) {
                  case ' ':
                    newState = 'A';
                    break;
                  case 'A':
                    newState = 'E';
                    break;
                  case 'E':
                    newState = 'F';
                    break;
                  default:
                    newState = ' ';
                }
                if(newState === ' ') {
                  delete attendance[user.uid]
                } else {
                  attendance[user.uid] = newState;
                }
                setAttendance(JSON.parse(JSON.stringify(attendance)));
                updateAttendance();
              };
              return (
                <ListItem
                  key={user.uid}
                  role="listitem"
                  button
                  onClick={nextState}
                >
                  <ListItemIcon>
                    <Box sx={{
                      borderRadius: '50%',
                      width: '20px',
                      height: '20px',
                      fontSize: 17,
                      textAlign: 'right',
                      fontWeight: 600,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      bgcolor: color
                    }}>
                      {attendingState}
                    </Box>
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${user.firstname} ${user.lastname}`} />
                </ListItem>
              );
            })}
          </List>
        </Paper>
      </Box>
    </Box>
  );
}

export default FencingAdminAttendanceCalendar;
