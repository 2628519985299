import Paper from "@mui/material/Paper";
import {
  CloudDownloadOutlined,
  CloudDownloadRounded,
  DescriptionOutlined, DownloadOutlined, DownloadRounded,
  FolderOutlined,
  SettingsOutlined
} from "@mui/icons-material";
import {Box, Button} from "@mui/material";
import dayjs from "dayjs";

import * as auth from '../../firebase/auth.js';

function FileElement(props) {
  const {data, openElement, onEdit} = props;
  const {time, title, type} = data;

  return (
    <Paper onClick={() => openElement(false) /* <- false = no download*/} sx={{my: 1, p: 2, pr: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}}>
      {type === 'folder' && <FolderOutlined width={5}/>}
      {type === 'file' && <DescriptionOutlined/>}
      <Box sx={{ml: 2, mr: 'auto', maxWidth: '50%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>{title}</Box>
      {type !== 'folder' && <Box ml='auto'>{dayjs(time).format('DD.MM.YYYY')}</Box>}

      {/*type === 'file' && <Button sx={{minWidth: '10px', color: 'white'}} onClick={(event => {
        event.stopPropagation();
        openElement(true);
      })}><DownloadRounded/></Button> */}

      {auth.hasTag('files.admin') && <Button onClick={(event => {
        event.stopPropagation();
        onEdit();
      })}><SettingsOutlined/></Button>}
    </Paper>
  )
}

export default FileElement;
