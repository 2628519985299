import {Box} from "@mui/material";
import DoorLock from "../../modules/adh/DoorLock";
import ChangeWifiKey from "../../modules/adh/ChangeWifiKey";

function AdhPage() {


  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: '40px',
      height: '100%',
    }}>
      <DoorLock/>
      <ChangeWifiKey/>
    </Box>
  );
}

export default AdhPage;
