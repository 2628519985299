import {Box, Button} from "@mui/material";
import {useNavigate} from "react-router-dom";

function ActivateAccountPage() {
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  return(
    <Box sx={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'centre',
        }}>
      <Box sx={{ textAlign: 'center', fontSize: 'h2.fontSize', marginBottom: '6rem' }}>
            T!AW
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-end', textAlign: 'center', marginBottom: '1rem' }}>
        Dein Account ist noch nicht aktiv.<br/>Bitte wende dich an Jan und teile Ihm deine E-Mail-Adresse mit um das zu ändern :D
      </Box>
      <Button variant='outlined' onClick={() => navigate('/blank?action=signin&token=' + queryParameters.get('retryToken'))}>
          Aktualisieren
      </Button>
    </Box>
  );
}

export default ActivateAccountPage;