import 'firebase/auth';
import {Box, Button, TextField, Typography} from "@mui/material";
import EmailRounded from '@mui/icons-material/EmailRounded';
import PersonRounded from '@mui/icons-material/PersonRounded';
import {useEffect, useState} from "react";

import * as auth from '../firebase/auth.js';
import {useNavigate} from "react-router-dom";

function RequestEmailPage() {
  const navigate = useNavigate();
  useEffect(() => {
    if(auth.IS_AUTHENTICATED) {
      navigate('/');
    }
  }, []);

  const [firstname, setFirstname] = useState(null);
  const [lastname, setLastname] = useState(null);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [enterName, setEnterName] = useState(false);
  const [mailSend, setMailSend] = useState(false);
    return (
        <Box sx={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'centre',
        }}>
          <Box sx={{ textAlign: 'center', fontSize: 'h2.fontSize', marginBottom: '6rem' }}>
            T!AW
          </Box>
          {!mailSend && <Box sx={{ display: 'flex', alignItems: 'flex-end', marginBottom: '1rem' }}>
            <EmailRounded sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField disabled={loading} id="email" label="E-Mail" variant="standard" onChange={(e) => setEmail(e.target.value.toLowerCase())} />
          </Box>}
          {!mailSend && enterName && <Box sx={{ display: 'flex', alignItems: 'flex-end', marginBottom: '1rem' }}>
            <PersonRounded sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField disabled={loading} id="name" label="Vor- und Nachname" variant="standard" onChange={(e) => {
              const name = e.target.value.split(' ');
              if(name.length < 2) {
                return;
              }
              const first = name[0];
              const last = e.target.value.substring(first.length);
              setFirstname(first);
              setLastname(last.trim());
            }} />
          </Box>}
          {mailSend && <Box sx={{ display: 'flex', alignItems: 'flex-end', textAlign: 'center', marginBottom: '1rem' }}>
            Wir haben eine E-mail an dich versand.<br/>Klicke auf den Link in der Nachricht um fortzufahren.
          </Box>}
          {!mailSend && <Button disabled={loading || !auth.checkEmailSyntax(email) || (enterName && (!firstname || !lastname))} variant='outlined' onClick={async () => {
            setLoading(true);
            const success = await auth.requestAuthenticationUrl(email, firstname, lastname);
            setEnterName(!success);
            setMailSend(success);
            setLoading(false);
          }}>
            {enterName ? 'Registrieren' : 'Anmelden'}
          </Button>}
        </Box>
    );
}

export default RequestEmailPage;
