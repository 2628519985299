import {
  AddCardRounded,
  InterestsRounded,
  PhishingRounded,
  ShoppingCartRounded,
  SportsBarRounded
} from "@mui/icons-material";

function TransactionIcon(props) {
  switch (props.kind) {
    case 'penalty':
      return (<PhishingRounded/>);
    case 'transfer':
      return (<AddCardRounded/>);
    case 'drinks':
      return (<SportsBarRounded/>);
    case 'reimbursement':
      return (<ShoppingCartRounded/>);
    default:
      return (<InterestsRounded/>);
  }
}

export default TransactionIcon;
