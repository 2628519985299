import {Box} from "@mui/material";

import * as auth from '../../firebase/auth.js';
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

function StartPage() {

  // REMOVE LATER
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/home/adh');
  }, []);

  return (<div></div>);
  // REMOVE LATER

  return (
    <Box sx={{p: 2, px: 5}}>
      <Box sx={{fontSize: 30, textAlign: 'center'}}>{auth.CURRENT_USER.firstname} {auth.CURRENT_USER.lastname} ({auth.CURRENT_USER.role})</Box>
    </Box>
  );
}

export default StartPage;
