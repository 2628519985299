import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel, MenuItem,
  OutlinedInput,
  Select
} from "@mui/material";
import {useState} from "react";
import UploadButton from "../UploadButton";

import * as auth from '../../firebase/auth.js';
import {collection, getFirestore, doc, addDoc} from "firebase/firestore";
import {getStorage, ref, uploadBytesResumable} from "firebase/storage";

function PenaltyAdder () {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState('');
  const [reason, setReason] = useState('');
  const [selected, setSelected] = useState('');
  const [file, setFile] = useState(null);


  const reset = () => {
    setLoading(false);
    setAmount('');
    setReason('');
    setSelected('');
    setFile(null);
  }

  const addPenalty = async () => {
    setLoading(true);
    const toUser = auth.ALL_USER.filter(user => user.uid === selected)[0];

    let penaltyAmount = parseFloat(amount);
    penaltyAmount = Math.abs(penaltyAmount);

    const penaltyData = {
      from: {
        uid: auth.CURRENT_USER.uid,
        name: auth.CURRENT_USER.firstname,
      },
      to: {
        uid: toUser.uid,
        name: toUser.firstname,
      },
      amount: penaltyAmount,
      reason: reason,
      status: 'pending',
      time: Date.now(),
    };

    const firestore = getFirestore();
    const penaltiesCollection = collection(firestore, 'penalties');
    const docRef = await addDoc(penaltiesCollection, penaltyData);

    if(!file) {
      reset();
      return;
    }

    const storage = getStorage();
    const storageRef = ref(storage, 'penalties/' + docRef.id);

    const metadata = {
      contentType: 'image/jpeg'
    };
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);
    uploadTask.on('state_changed', () => {}, (error) => {
      alert(error);
      console.log(error);
    }, () => {
      setLoading(false);
      reset();
    });
  }

  return (
    <Paper sx={{maxWidth: '400px', width: '100dvw', padding: 2, mb: 2, mt: 1, mx: 'auto'}}>
      <Box sx={{textAlign:'center'}}>
        Neue Einhängung
      </Box>
      <FormControl sx={{mt: 1, width: '100%'}} disabled={loading}>
        <InputLabel id="user-select-label" htmlFor='user-select'>Bewohner</InputLabel>
        <Select
          labelId="user-select-label"
          id='user-select'
          value={selected}
          onChange={(event) => setSelected(event.target.value)}
          input={<OutlinedInput label="Bewohner" />}
        >
          {auth.ALL_USER.map((currentUser) => (
            <MenuItem
              key={currentUser.uid}
              value={currentUser.uid}
            >
              {currentUser.firstname + ' ' + currentUser.lastname}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{mt: 1}} disabled={loading}>
        <InputLabel htmlFor="outlined-adornment-amount">Betrag in €</InputLabel>
        <OutlinedInput
          id="outlined-adornment-amount"
          label="Betrag in €"
          value={amount}
          onChange={event => {
            const value = event.target.value;
            setAmount(value.replaceAll(',','.'));
          }}
        />
      </FormControl>
      <FormControl fullWidth sx={{mt: 1}} disabled={loading}>
        <InputLabel htmlFor="outlined-adornment-details">Begründung</InputLabel>
        <OutlinedInput
          id="outlined-adornment-details"
          label="Begründung"
          value={reason}
          onChange={event => {
            const value = event.target.value;
            setReason(value);
          }}
        />
      </FormControl>
      <UploadButton accept={'image/jpeg'} text={file ? 'Bild Hinzugefügt' : 'Bild Hinzufügen'} color={file ? 'success' : 'primary'} sx={{mt: 1, height: '40px'}} disabled={loading} onChange={(file) => {setFile(file)}}/>
      <Button variant='contained' fullWidth sx={{mt: 1, height: '40px'}} disabled={(() => {
        if(loading) {
          return true;
        }
        if(reason.length < 3) {
          return true;
        }
        if(!selected) {
          return true;
        }
        return isNaN(parseFloat(amount));
      })()} onClick={addPenalty}>
        {loading ? (<CircularProgress size={30}/>) : 'Einhängung Eintragen'}
      </Button>
    </Paper>
  );
}

export default PenaltyAdder;