import {Box} from "@mui/material";
import PenaltyView from "../../modules/penalties/PenaltyView";

function PenaltyPage() {

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column'
    }}>
      <PenaltyView/>
    </Box>
  );
}

export default PenaltyPage;
