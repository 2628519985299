import TransactionView from "../../modules/transactions/TransactionView";

import * as auth from '../../firebase/auth.js';
import {
  Box, Button,
  CircularProgress,
  FormControl, Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select
} from "@mui/material";
import {useEffect, useState} from "react";
import {collection, getDocs, getFirestore, orderBy, query, limit} from "firebase/firestore";
import Paper from "@mui/material/Paper";
import TransactionKindName from "../../modules/transactions/TransactionKindName";
import {useNavigate} from "react-router-dom";
import {getFunctions, httpsCallable} from "firebase/functions";

function TransactionsAdminPage() {
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);

  const firestore = getFirestore();
  const [user, setUser] = useState(null);
  const [selected, setSelected] = useState('');

  // For new transaction
  const [amount, setAmount] = useState('');
  const [kind, setKind] = useState('');
  const [details, setDetails] = useState('');
  const [transactionPending, setTransactionPending] = useState(false);

  useEffect(() => {
    if(!auth.hasTag('transactions.admin')) {
      navigate('/');
      return;
    }
    const fetch = async() => {
      const newUser = auth.ALL_USER;
      for(let currentUser of newUser) {
          const collectionRef = collection(firestore, 'user/' + currentUser.uid + '/transactions');
          currentUser.transactionDataPromise = getDocs(query(collectionRef, orderBy('time', 'desc'), limit(1)));
      }
      for(let currentUser of newUser) {
        const snapshot = await currentUser.transactionDataPromise;
        const docs = snapshot.docs;
        if(docs.length < 1) {
          currentUser.balance = 0;
        } else {
          currentUser.balance = docs[0].data().newBalance;
        }
      }
      setUser(newUser);
      const oldSelected = queryParameters.get('selected');
      if(oldSelected) {
        setSelected(oldSelected);
      }
    }
    fetch();
  }, []);
  const formatNumber = (number) => {
    number *= 100;
    number = Math.round(number);
    number /= 100;
    if (number >= 0) {
      number = '+' + number;
    }
    return number + '€';
  };

  const executeTransaction = async () => {
    setTransactionPending(true);
    const transactionData = {
      userId: selected,
      amount: parseFloat(amount),
      kind: kind,
      details: details,
    };
    const functions = getFunctions();
    const executeTransaction = httpsCallable(functions, 'executeTransaction');
    const response = await executeTransaction(transactionData);
    if(response.data.error) {
      alert(response.data.error);
      setTransactionPending(false);
      return;
    }
    navigate('/home/transactions/admin?selected=' + selected);
    navigate(0);
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column'}}>
      {!user &&
        <Box sx={{position: 'fixed',  left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
          <CircularProgress size={80}/>
        </Box>
      }
      {!!user && <FormControl sx={{ m: 1, width: '300px', marginY: 2 }}>
        <InputLabel id="user-select-label">Verfügbare Konten</InputLabel>
        <Select
          labelId="user-select-label"
          id="demo-multiple-name"
          value={selected}
          onChange={(event) => setSelected(event.target.value)}
          input={<OutlinedInput label="Verfügbare Konten" />}
        >
          {user.map((currentUser) => (
            <MenuItem
              key={currentUser.uid}
              value={currentUser.uid}
            >
              {currentUser.firstname + ' ' + currentUser.lastname + ' (' + formatNumber(currentUser.balance) + ')'}
            </MenuItem>
          ))}
        </Select>
      </FormControl>}

      {!!selected && <Paper sx={{maxWidth: '400px', padding: 2, mb: 2}}>
        <Box sx={{textAlign:'center'}}>
          Neue Transaktion
        </Box>
        <FormControl fullWidth sx={{mt: 1}} disabled={transactionPending}>
          <InputLabel htmlFor="outlined-adornment-amount">Betrag</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start">€</InputAdornment>}
            label="Betrag"
            value={amount}
            onChange={event => {
              const value = event.target.value;
              setAmount(value.replaceAll(',','.'));
            }}
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth sx={{mt: 1}} disabled={transactionPending}>
            <InputLabel id="demo-simple-select-standard-label">Art</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={kind}
              onChange={event => setKind(event.target.value)}
              label="Art"
            >
              <MenuItem value={'transfer'}><TransactionKindName kind='transfer'/></MenuItem>
              <MenuItem value={'reimbursement'}><TransactionKindName kind='reimbursement'/></MenuItem>
              <MenuItem value={'drinks'}><TransactionKindName kind='drinks'/></MenuItem>
              <MenuItem value={'penalty'}><TransactionKindName kind='penalty'/></MenuItem>
              <MenuItem value={'other'}><TransactionKindName kind='other'/></MenuItem>
            </Select>
          </FormControl>
        <FormControl fullWidth sx={{mt: 1}} disabled={transactionPending}>
          <InputLabel htmlFor="outlined-adornment-details">Kommentar</InputLabel>
          <OutlinedInput
            id="outlined-adornment-details"
            label="Kommentar"
            value={details}
            onChange={event => {
              const value = event.target.value;
              setDetails(value);
            }}
          />
        </FormControl>
        <Button variant='contained' fullWidth sx={{mt: 1}} disabled={(() => {
          if(transactionPending) {
            return true;
          }
          if(isNaN(parseFloat(amount))) {
            return true
          }
          return !kind;

        })()} onClick={executeTransaction}>
          Transaktion Ausführen
        </Button>
      </Paper>}

      {!!selected && <TransactionView userId={selected}/>}
    </Box>
  );
}

export default TransactionsAdminPage;
