import {Box, Button} from "@mui/material";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import FileList from "../../modules/files/FileList";
import {getFirestore, doc, getDoc} from "firebase/firestore";
import {useEffect, useState} from "react";

function FilesPage() {
  const firestore = getFirestore();
  const location = useLocation();
  const navigate = useNavigate();
  const { folderId } = useParams();
  const [folderName, setFolderName] = useState('Dateien');
  const [parent, setParent] = useState('');

  const fetchFolderData = async () => {
    if(!folderId || folderId.length < 20) {
      setFolderName('Dateien');
      return;
    }
    const docRef = doc(firestore, 'files/' + folderId);
    const snapshot = await getDoc(docRef);
    const data = snapshot.data();
    if(!data) {
      return;
    }
    setParent(data.parent || '');
    if(data.title) {
      setFolderName(data.title);
    }
  };

  useEffect(() => {
    fetchFolderData();
  }, [location]);

  return(
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column'
    }}>
      <Box sx={{
        fontSize: 25,
        fontWeight: 600,
        textAlign: 'center',
        marginBottom: '5px',
        marginTop: '10px',
        cursor: 'pointer',
      }}
      onClick={() => {
        navigate('/home/files/' + parent);
      }}>
        {folderName}
      </Box>
      <FileList folderId={folderId || ''}/>
    </Box>
  );
}

export default FilesPage;
