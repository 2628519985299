import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import {useEffect} from "react";

import * as auth from '../../firebase/auth.js';
import {getFunctions, httpsCallable} from "firebase/functions";
import {Box} from "@mui/material";

const updateFencing = async (fencing, notFencing) => {
  if(fencing.length < 1 && notFencing.length < 1) {
    return;
  }
  const fencingUpdate = {};
  for(let user of fencing) {
    fencingUpdate[user.uid] = true;
  }
  for(let user of notFencing) {
    fencingUpdate[user.uid] = false;
  }
  const functions = getFunctions();
  const getUser = httpsCallable(functions, 'setFencing');
  const response = await getUser(fencingUpdate);
  if(response.data.error) {
    alert(response.data.error);
  } else {
    await auth.fetchAllUser();
  }
}

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function FencingAdminTransferList() {
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  useEffect(() => {
    const newLeft = [];
    const newRight = [];
    for(const user of auth.ALL_USER) {
      if(user.tags && user.tags.includes('fencing')) {
        newRight.push(user);
      } else {
        newLeft.push(user);
      }
    }
    setRight(newRight);
    setLeft(newLeft);
  }, []);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    updateFencing(leftChecked, []);
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    updateFencing([], rightChecked);
  };


  const customList = (items) => (
    <Paper sx={{ width: '35dvw', maxWidth: 300, height: 230, overflow: 'auto' }}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemText sx={{color: checked.indexOf(value) !== -1 ? 'lightBlue' : ''}} id={labelId} primary={`${value.firstname} ${value.lastname}`} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>
        <Box sx={{margin: 0, textAlign: 'center'}}>Nein</Box>
        {customList(left)}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Box sx={{margin: 0, textAlign: 'center'}}>Ja</Box>
        {customList(right)}
      </Grid>
    </Grid>
  );
}

export default FencingAdminTransferList;
