import {Box, Button} from "@mui/material";
import {useEffect, useState} from "react";
import FileElement from "./FileElement";
import {useLocation, useNavigate} from "react-router-dom";
import LoadingIndicator from "../LoadingIndicator";
import {collection, getDocs, getFirestore, query, where} from "firebase/firestore";
import {getBlob, getDownloadURL, getStorage, ref} from "firebase/storage";

import * as auth from "../../firebase/auth";

function FileList(props) {
  const firestore = getFirestore();
  const storage = getStorage();
  const navigate = useNavigate();
  const location = useLocation();
  const {folderId} = props;
  const [elements, setElements] = useState(null);
  const [loading, setLoading] = useState(false);

  const editElement = (element) => {
    navigate('/home/files/' + element.uid + '/edit');
  }

  const createElement = (type) => {
    let newLocation = '/home/files/' + type + '/create';
    if(folderId) {
      newLocation += '?parent=' + folderId;
    }
    navigate(newLocation);
  }

  const openFile  = async (element, download) => {
    setLoading(true);
    const storageRef = ref(storage, 'files/' + element.uid);
    const blob = await getBlob(storageRef);
    const blobUrl = window.URL.createObjectURL(blob);
    if (!download) {
      window.location.href = blobUrl;
    } else {
      /*
      Download option is currently unused!

      const anchorElement = document.createElement('a');
      document.body.appendChild(anchorElement);
      anchorElement.style.display = 'none';
      anchorElement.href = blobUrl;
      anchorElement.download = element.title;
      anchorElement.click();
       */
    }
    // Breaks compatibility with iPadOs -> window.URL.revokeObjectURL(blobUrl);
    setLoading(false);
  };

  const fetchData = async () => {
      const collectionRef = collection(firestore, 'files');
      const snapshot = await getDocs(query(collectionRef, where('parent', '==', folderId || '')));
      const docs = snapshot.docs;
      setElements(docs.map(docSnapshot => {
        const data = docSnapshot.data();
        data.uid = docSnapshot.id;
        return data;
      }));
  };

  useEffect(() => {
    fetchData();
  }, [location]);

  if(!elements || loading) {
    return (<LoadingIndicator/>);
  }

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column'
    }}>
      {auth.hasTag('files.admin') && <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        gap: '20px'
      }}>
        <Button variant='outlined' onClick={() => createElement('file')}>Datei Hochladen</Button>
        <Button variant='outlined' onClick={() => createElement('folder')}>Ordner Erstellen</Button>
      </Box>}
      {elements.filter(element => element.type === 'folder' && (auth.hasTag('files.admin') || element.accessFor.includes(auth.CURRENT_USER.role))).map(element => {
        return (
          <FileElement onEdit={() => editElement(element)} openElement={() => navigate('/home/files/' + element.uid)} key={element.uid} data={element}/>
        );
      })}
      {elements.filter(element => element.type !== 'folder').sort((a, b) => b.time - a.time).map(element => {
        return (
          <FileElement onEdit={() => editElement(element)} openElement={(download) => openFile(element, download)} key={element.uid} data={element}/>
        );
      })}
    </Box>
  );
}

export default FileList;
