import {Box} from "@mui/material";

function TransactionKindName(props) {
  switch (props.kind) {
    case 'penalty':
      return (<Box sx={{display: 'inline-block'}}>Einhängung</Box>);
    case 'transfer':
      return (<Box sx={{display: 'inline-block'}}>Überweisung</Box>);
    case 'drinks':
      return (<Box sx={{display: 'inline-block'}}>Getränke</Box>);
    case 'reimbursement':
      return (<Box sx={{display: 'inline-block'}}>Erstattung</Box>);
    default:
      return (<Box sx={{display: 'inline-block'}}>Sonstieges</Box>);
  }
}

export default TransactionKindName;
