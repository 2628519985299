import Router from "./modules/Router";

import {ThemeProvider, createTheme} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {Box} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from "@mui/x-date-pickers";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline/>
          <div className="app" style={{position: 'fixed', top: 0, left: 0, width:'100dvw', height: '100dvh', overflow: 'hidden'}}>
            <Box sx={{
                height: '100dvh',
                width: '100dvw',
                maxWidth: '800px',
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                top: 0,
            }}>
              <Router/>
            </Box>
          </div>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
