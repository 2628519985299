import {useNavigate, useParams} from "react-router-dom";
import {Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, OutlinedInput, Select} from "@mui/material";
import {useEffect, useState} from "react";
import LoadingIndicator from "../../modules/LoadingIndicator";
import Paper from "@mui/material/Paper";
import {getFirestore, doc, getDoc, setDoc, deleteDoc, collection, query, getDocs, where} from "firebase/firestore";
import { getStorage, ref, deleteObject, uploadBytesResumable } from "firebase/storage";

import * as auth from '../../firebase/auth.js';
import dayjs from "dayjs";
import {DateCalendar} from "@mui/x-date-pickers";
import UploadButton from "../../modules/UploadButton";

const randId = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

function FileEditPage() {
  const navigate = useNavigate();
  const firestore = getFirestore();
  const storage = getStorage();

  const { data, action } = useParams();
  const queryParameters = new URLSearchParams(window.location.search);

  const [element, setElement] = useState();
  const [newTitle, setNewTitle] = useState('');
  const [newTime, setNewTime] = useState(0);
  const [newAccessFor, setNewAccessFor] = useState('');
  const [newFile, setNewFile] = useState(null);

  const [saving, setSaving] = useState(false);
  const [savingProgress, setSavingProgress] = useState(0);

  const fetchData = async () => {
    let elementData;
    switch (action) {
      case 'create':
        elementData = {
          uid: randId(20),
          type: data,
          title: '',
          parent: queryParameters.get('parent') || '',
        };
        if(data === 'folder') {
          elementData.accessFor = JSON.stringify(auth.ALL_ROLES);
        } else {
          elementData.time = dayjs(Date.now());
        }
        break;
      case 'edit':
        const uid = data;
        const docRef = doc(firestore, 'files/' + uid);
        const snapshot = await getDoc(docRef);
        elementData = snapshot.data();
        if(!elementData) {
          alert('invalid document');
          navigate('/');
          return;
        }
        elementData.uid = uid;
        if(elementData.type === 'folder') {
          elementData.accessFor = JSON.stringify(auth.ALL_ROLES.filter(role => elementData.accessFor.includes(role)));
        } else {
          elementData.time = dayjs(elementData.time);
        }
        break;
      default:
        alert('invalid action');
        navigate('/');
        return;
    }
    setElement(elementData);
    setNewTitle(elementData.title);
    if(elementData.type === 'folder') {
      setNewAccessFor(elementData.accessFor);
    } else {
      setNewTime(elementData.time);
    }
  };

  const deleteElement = async () => {
    if(!window.confirm('Bist du dir sicher, dass du "' + newTitle + '" löschen möchtest?')) {
      return;
    }
    setSaving(true);
    await deleteRecursively(element);
    navigate('/home/files/' + element.parent);
  }

  const deleteRecursively = async (elementToDelete) => {
    if(elementToDelete.type === 'folder') {
      const collectionRef = collection(firestore, 'files');
      const snapshot = await getDocs(query(collectionRef, where('parent', '==', elementToDelete.uid)));
      const docs = snapshot.docs;
      const subElements = docs.map(docSnapshot => {
        const data = docSnapshot.data();
        data.uid = docSnapshot.id;
        return data;
      });
      subElements.forEach(deleteRecursively);
    } else {
      const storageRef = ref(storage, 'files/' + elementToDelete.uid);
      await deleteObject(storageRef);
    }

    const docRef = doc(firestore, 'files/' + elementToDelete.uid);
    await deleteDoc(docRef);
  }

  const save = async () => {
    if(!canSave()) {
      return;
    }
    setSaving(true);
    const newData = {
      type: element.type,
      title: newTitle,
      parent: element.parent,
    };
    if(element.type === 'folder') {
      newData.accessFor = JSON.parse(newAccessFor);
    } else {
      newData.time = newTime.valueOf();
    }
    const docRef = doc(firestore, 'files/' + element.uid);
    await setDoc(docRef, newData);
    if(newFile) {
      const sotrageRef = ref(storage,'files/' + element.uid);
      const metadata = {
        contentType: 'application/pdf'
      };
      const uploadTask = uploadBytesResumable(sotrageRef, newFile, metadata);
      uploadTask.on('state_changed', (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        setSavingProgress(progress);

      }, (error) => {
        alert(error);
        console.log(error);
      }, () => {
        navigate('/home/files/' + element.parent);
      });
    } else {
      navigate('/home/files/' + element.parent);
    }
  };

  const canSave = () => {
    if(action === 'create' && element.type === 'file') {
      return newTitle.length > 1 && newFile;
    }
    if(action === 'edit' && element.type === 'file') {
      return newTitle.length > 1 && (newTitle !== element.title || newTime !== element.time || newFile);
    }
    if(element.type === 'folder') {
      return newTitle.length > 1 && newAccessFor && (newTitle !== element.title || newAccessFor !== element.accessFor);
    }
    return false;
  }

  useEffect(() => {
    if(!auth.hasTag('files.admin')) {
      navigate('/');
      return;
    }
    fetchData();
  }, []);

  if(!element) {
    return (<LoadingIndicator/>);
  }

  return(
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }}>
      <Box sx={{
        fontSize: 25,
        fontWeight: 600,
        textAlign: 'center',
        marginBottom: '5px',
        marginTop: '10px',
      }}>
        {element.type === 'folder' ? 'Ordner' : 'Datei'} {action === 'create' ? 'erstellen' : 'editieren'}
      </Box>
      <Paper sx={{p:1, width: '100dvw', maxWidth: '400px'}}>
        <FormControl fullWidth sx={{mt: 1}} disabled={saving}>
          <InputLabel htmlFor="title">Bezeichnung</InputLabel>
          <OutlinedInput
            id="title"
            label="Bezeichnung"
            value={newTitle}
            onChange={event => setNewTitle(event.target.value)}
          />
        </FormControl>
        {element.type === 'file' && <DateCalendar sx={{width: '100dvw', maxWidth: '300px'}} value={newTime} onChange={value => setNewTime(value)} disabled={saving}/>}
        {element.type === 'file' && <UploadButton accept={'application/pdf'} text={action === 'create' ? 'PDF Auswählen' : 'PDF Ändern'} color={newFile ? 'success' : 'primary'} disabled={saving} onChange={(file) => {setNewFile(file)}}/>}
        {element.type === 'folder' && <FormControl variant="outlined" fullWidth sx={{mt: 2  }} disabled={saving}>
          <InputLabel id="access-for-label">Zugriff Ab</InputLabel>
          <Select
            labelId="access-for-label"
            id="access-for"
            value={newAccessFor}
            onChange={event => setNewAccessFor(event.target.value)}
            label="Zugriff Ab"
          >
            <MenuItem value={JSON.stringify(auth.ALL_ROLES)}>Gast</MenuItem>
            <MenuItem value={JSON.stringify(auth.ALL_ROLES.filter(e => e !== 'Gast'))}>Fux</MenuItem>
            <MenuItem value={JSON.stringify(auth.ALL_ROLES.filter(e => e !== 'Gast' && e !== 'Fux'))}>Bursch</MenuItem>
          </Select>
        </FormControl>}
        {!saving && <Button variant={'contained'} sx={{mt: 2, height: '35px'}} fullWidth disabled={!canSave()} onClick={() => save()}>{action === 'create' ? 'Erstellen' : 'Speichern'}</Button>}
        {!saving && action === 'edit' && <Button variant={'text'} color='error' sx={{mt: 1, height: '20px', fontSize: '9px'}} fullWidth onClick={() => deleteElement()}>{element.type === 'folder' ? 'Ordner' : 'Datei'} Löschen</Button>}
        {!!saving && <Button variant={'outlined'} sx={{mt: 2, height: '35px'}} fullWidth><CircularProgress size={25} value={savingProgress} variant={savingProgress !== 0 ? 'determinate' : 'indeterminate'}/></Button>}
      </Paper>
    </Box>
  );
}

export default FileEditPage;
