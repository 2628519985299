import TransactionView from "../../modules/transactions/TransactionView";

import * as auth from '../../firebase/auth.js';
import {useNavigate} from "react-router-dom";
import {Box, Button} from "@mui/material";

function TransactionPage() {
  const navigate = useNavigate();

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column'
    }}>
      {auth.hasTag('transactions.admin') && <Button variant='outlined' sx={{margin: 1}} onClick={() => navigate('/home/transactions/admin')}>
        Zur Kassenwart Ansicht
      </Button>}
      <TransactionView userId={auth.CURRENT_USER.uid}/>
    </Box>
  );
}

export default TransactionPage;
