import {WifiRounded} from '@mui/icons-material';
import {Button, CircularProgress, FormControl, InputLabel, OutlinedInput} from "@mui/material";
import {useState} from "react";

import * as auth from '../../firebase/auth.js';
import Paper from "@mui/material/Paper";
import {getFunctions, httpsCallable} from "firebase/functions";

function ChangeWifiKey() {
  const [key, setKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const saveKey = async () => {
    setLoading(true);
    const functions = getFunctions();
    const setWifiKey = httpsCallable(functions, 'setWifiKey');
    await setWifiKey({
      wifiKey: key,
    });
    setLoading(false);
    setDone(true);
  };

  return (
    <Paper sx={{maxWidth: '350px', width: '95dvw', padding: 2, display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'column', borderRadius: '20px'}}>
      <WifiRounded fontSize={'large'}/>

      <FormControl fullWidth sx={{mt: 1}} disabled={true}>
        <InputLabel htmlFor="outlined-adornment-mail">Anmeldename</InputLabel>
        <OutlinedInput
          id="outlined-adornment-mail"
          label="Anmeldename"
          value={auth.CURRENT_USER.mail}
        />
      </FormControl>

      <FormControl fullWidth sx={{mt: 1}} disabled={loading}>
        <InputLabel htmlFor="outlined-adornment-key">Passwort</InputLabel>
        <OutlinedInput
          id="outlined-adornment-key"
          label="Passwort"
          type="password"
          value={key}
          onChange={event => {
            const value = event.target.value;
            setKey(value.replace('"', ''));
          }}
        />
      </FormControl>

      <Button onClick={() => saveKey()} color={done ? 'success' : 'primary'} variant={"contained"} sx={{height: '40px', width: '120px', m: 2}} disabled={loading || key.length < 10}>{loading ? <CircularProgress size={35}/> : 'Speichern'}</Button>
    </Paper>
  );
}

export default ChangeWifiKey;
