import {BottomNavigation, BottomNavigationAction} from "@mui/material";
import {
  DescriptionOutlined,
  EuroRounded,
  PhishingRounded,
  HouseRounded
} from "@mui/icons-material";

import * as auth from '../firebase/auth.js';
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import FencingIcon from "../svgs/FencingIcon";

function BottomNavigationBar() {

  // ADD BACK LATER
  // <BottomNavigationAction label={auth.CURRENT_USER.firstname} value='start' icon={<PermIdentityRounded/>}/>
  // ADD BACK LATER

  const navigate = useNavigate();
  let startLocation = window.location.href.split('/home/')[1].split('/')[0].split(['\\?'])[0];

  // REMOVE LATER
  if(startLocation === 'start') {
    startLocation = 'adh';
  }
  // REMOVE LATER

  const [location, setLocation] = useState(startLocation);

  return (
    <BottomNavigation value={location} onChange={(event, value) => {
      setLocation(value);
      navigate('/home/' + value);
    }}>
      <BottomNavigationAction label='AdH' value='adh' icon={<HouseRounded/>}/>
      <BottomNavigationAction label='Konto' value='transactions' icon={<EuroRounded/>}/>
      <BottomNavigationAction label='Einhängungen' value='penalties' icon={<PhishingRounded/>}/>
      <BottomNavigationAction label='Pauken' value='fencing' icon={<FencingIcon/>}/>
      <BottomNavigationAction label='Dateien' value='files' icon={<DescriptionOutlined/>}/>
    </BottomNavigation>
  );
}

export default BottomNavigationBar;
