import {Accordion, AccordionDetails, AccordionSummary, Box} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import TransactionIcon from "./TransactionIcon";
import {useEffect, useState} from "react";
import {getFirestore, collection, query, orderBy, getDocs} from "firebase/firestore";
import TransactionKindName from "./TransactionKindName";
import dayjs from "dayjs";
import LoadingIndicator from "../LoadingIndicator";

function TransactionView(props) {
  const firestore = getFirestore();
  const [transactions, setTransactions] = useState(null);
  const [expanded, setExpanded] = useState('');

  useEffect(() => {
    const fetch = async() => {
      const {userId} = props;
      const collectionRef = collection(firestore, 'user/' + userId + '/transactions');
      const snapshot = await getDocs(query(collectionRef, orderBy('time', 'desc')));
      const docs = snapshot.docs;
      setTransactions(docs.map(docObj => {
        const data = docObj.data();
        data.uid = docObj.id;
        return data;
      }));
    }
    fetch();
   }, [props]);

  const formatNumber = (number) => {
    number *= 100;
    number = Math.round(number);
    number /= 100;
    if (number >= 0) {
      number = '+' + number;
    }
    return number + '€';
  };

  const formatDate = (millis) => {
    const date = dayjs(millis);
    return date.format('DD.MM.YYYY');
  }

  const getCurrentBalance = () => {
    if(transactions.length < 1) {
      return 0;
    }
    const lastTransaction = transactions[0];
    return lastTransaction.newBalance;
  }

  return (
    <Box sx={{width: '100%'}}>
      {!transactions &&
        <LoadingIndicator/>
      }
      {!!transactions && <Box sx={{textAlign: 'center', width: '100%', fontWeight: 600, padding: 1, fontSize: 18}}>Kontostand: <Box color={getCurrentBalance() < 0 ? 'red' : 'green'} sx={{display: 'inline-block'}}>{formatNumber(getCurrentBalance())}</Box></Box>}
      {!!transactions && transactions.map(transaction => {
        return (
          <Accordion key={transaction.uid} expanded={transaction.uid === expanded} onClick={() => {
            if(expanded === transaction.uid) {
              setExpanded('');
            } else {
              setExpanded(transaction.uid);
            }
          }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <TransactionIcon kind={transaction.kind}/>
              <Box color={transaction.amount < 0 ? 'red' : 'green'} sx={{display: 'inline-block', marginLeft: 1, fontWeight: 600}}>{formatNumber(transaction.amount)}</Box><Box sx={{marginLeft: 'auto'}}>{formatDate(transaction.time)}</Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{marginBottom: 1}}>
                <Box sx={{display: 'inline-block', fontWeight: 600}}>Art:</Box> <TransactionKindName kind={transaction.kind}/>
              </Box>
              <Box sx={{marginBottom: 1}}>
                <Box sx={{display: 'inline-block', fontWeight: 600}}>Kommentar:</Box> {transaction.details || '-'}
              </Box>
              <Box sx={{marginBottom: 1}}>
                <Box sx={{display: 'inline-block', fontWeight: 600}}>Neuer Kontostand :</Box> {formatNumber(transaction.newBalance)}
              </Box>
              <Box sx={{fontSize: 13, color: 'grey'}}>
                {transaction.uid} ({transaction.signedBy})
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}

export default TransactionView;
