import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import RequestEmailPage from "../pages/RequestEmailPage";

import BlankPage from "../pages/BlankPage";
import ActivateAccountPage from "../pages/ActivateAccountPage";
import HomeMenu from "./HomeMenu";
import StartPage from "../pages/home/StartPage";
import FencingAdminPage from "../pages/admin/FencingAdminPage";
import FencingPage from "../pages/home/FencingPage";
import TransactionsAdminPage from "../pages/admin/TransactionsAdminPage";
import TransactionPage from "../pages/home/TransactionPage";
import FilesPage from "../pages/home/FilesPage";
import FileEditPage from "../pages/admin/FileEditPage";
import PenaltyPage from "../pages/home/PenaltyPage";
import ChangeWifiKey from "./adh/ChangeWifiKey";
import AdhPage from "../pages/home/AdhPage";

const router = createBrowserRouter([
  {
    path: "/requestEmail",
    element: <RequestEmailPage/>,
  },
  {
    path: '/activateAccount',
    element: <ActivateAccountPage/>,
  },
  {
    path: "/blank",
    element: <BlankPage/>,
  },
  {
    path: '/',
    element: <BlankPage/>,
  },
  {
    path: '/home',
    element: <HomeMenu/>,
    children: [
      {
        path: 'start',
        element: <StartPage/>,
      },
      {
        path: 'fencing',
        element: <FencingPage/>,
      },
      {
        path: 'fencing/admin',
        element: <FencingAdminPage/>,
      },
      {
        path: 'transactions',
        element: <TransactionPage/>,
      },
      {
        path: 'transactions/admin',
        element: <TransactionsAdminPage/>,
      },
      {
        path: 'files/',
        element: <FilesPage/>
      },
      {
        path: 'files/:folderId',
        element: <FilesPage/>
      },
      {
        path: 'files/:data/:action',
        element: <FileEditPage/>
      },
      {
        path: 'penalties/',
        element: <PenaltyPage/>
      },
      {
        path: 'adh/',
        element: <AdhPage/>
      }
    ]
  }
]);

function Router() {
  return (
      <RouterProvider router={router} />
  );
}

export default Router;
