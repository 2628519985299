import {Box, CircularProgress} from "@mui/material";

function LoadingIndicator() {
  return (
    <Box sx={{position: 'fixed',  left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
      <CircularProgress size={80}/>
    </Box>
  );
}

export default LoadingIndicator;
