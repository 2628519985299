import Paper from "@mui/material/Paper";
import {Box, Button, CircularProgress} from "@mui/material";
import {LockOutlined, LockOpenOutlined, KeyOutlined} from "@mui/icons-material";
import {useState} from "react";
import {getFirestore, collection, addDoc, serverTimestamp} from "firebase/firestore";

import * as auth from '../../firebase/auth.js';

const OPEN_DURATION = 4000;

function DoorLock() {
  const [openTime, setOpenTime] = useState(0);

  const decreaseTime = (time) => {
    if(time < 0) {
      return;
    }
    setOpenTime(time);
    const newTime = time - 100;
    window.setTimeout(decreaseTime, 100, newTime);
  }

  const openLock = async () => {
    setOpenTime(OPEN_DURATION);
    const firestore = getFirestore();
    const doorColl = collection(firestore, 'doorAccess');
    const newDoc = {
      user: auth.CURRENT_USER.uid,
      name: auth.CURRENT_USER.firstname + ' ' + auth.CURRENT_USER.lastname,
      time: serverTimestamp(),
    }
    await addDoc(doorColl, newDoc);
    window.setTimeout(decreaseTime, 300, OPEN_DURATION);
  }

  return (
    <Paper sx={{maxWidth: '350px', width: '95dvw', display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'column', borderRadius: '20px'}}>
      <Box sx={{
        fontSize: 30,
        m: 0,
        fontWeight: 600,
        textAlign: 'center',
        justifyContent: 'center',
        marginBottom: '5px',
        marginTop: '10px',
      }}>
        Türschloss
      </Box>
      <Button onClick={() => openLock()} sx={{height: '80px', width: '80px', borderRadius: '50%', m: 2, position: 'relative'}} disabled={openTime > 0}>
          <CircularProgress variant={'determinate'} value={openTime/OPEN_DURATION*100} size={90} sx={{height: '60px', width: '60px', position: 'absolute'}}/>
          {(openTime <= 0 || openTime === OPEN_DURATION) && <LockOutlined sx={{height: '60px', width: '60px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/>}
          {(openTime > 0 && openTime < OPEN_DURATION) && <LockOpenOutlined sx={{height: '60px', width: '60px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/>}
        </Button>
      </Paper>
  );
}

export default DoorLock;