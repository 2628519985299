import {useEffect, useState} from "react";
import {Button} from "@mui/material";

function UploadButton(props) {
  const {onChange, sx, accept, disabled, text, color} = props;
  const [id] = useState(Math.random + '');

  const getInputElement = () => {
    return document.getElementById(id);
  }

  useEffect(() => {
    const element = getInputElement();
    element.addEventListener('change', (event) => {
      if(!event.target.files || event.target.files.length < 1) {
        return;
      }
      onChange(event.target.files[0]);
    });
  }, []);

  return (
    <div>
      <input id={id} accept={accept} type='file' style={{display: 'none'}}/>
      <Button fullWidth sx={sx} color={color} disabled={disabled} variant='outlined' onClick={() => getInputElement().click()}>{text}</Button>
    </div>
  );
}

export default UploadButton;
