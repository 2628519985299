import {browserLocalPersistence, getAuth, signInWithCustomToken} from 'firebase/auth';
import {getFunctions, httpsCallable} from 'firebase/functions'
import 'firebase/auth';
import {getFirestore, doc, getDoc} from "firebase/firestore";

const auth = getAuth();

const ALL_ROLES = ['Gast', 'Fux', 'Bursch'];

auth.languageCode = 'de';
auth.setPersistence(browserLocalPersistence);

auth.onAuthStateChanged((user) => {
  if(!!user) {
    fetchAllUser();
  }
  if(IS_AUTHENTICATED === !!user) {
    return;
  }
  IS_AUTHENTICATED = !!user;
  window.localStorage.setItem('isAuthenticated', IS_AUTHENTICATED ? 'true' : 'false');
});
let IS_AUTHENTICATED = window.localStorage.getItem('isAuthenticated') === 'true';
let CURRENT_USER = null;
let ALL_USER = JSON.parse(window.localStorage.getItem('allUser') || '[]');

const fetchAllUser = async () => {
  const functions = getFunctions();
  const getUser = httpsCallable(functions, 'getUser');
  const response = await getUser({});
  const newAllUser = response.data.user;

  const firestore = getFirestore();
  const docRef = doc(firestore, 'user', CURRENT_USER.uid);
  const newCurrentUserData = (await getDoc(docRef)).data();
  newCurrentUserData.uid = docRef.id;
  ALL_USER = newAllUser;
  CURRENT_USER = newCurrentUserData;
  window.localStorage.setItem('allUser', JSON.stringify(newAllUser));
  window.localStorage.setItem('user', JSON.stringify(newCurrentUserData));
}

if(IS_AUTHENTICATED) {
  CURRENT_USER = JSON.parse(window.localStorage.getItem('user'));
}

const requestAuthenticationUrl = async (mail, firstname, lastname) => {
    if(!checkEmailSyntax(mail)) {
      return false;
    }
    const functions = getFunctions();
    const sendSignInMail = httpsCallable(functions, 'sendSignInMail');
    const response = await sendSignInMail({'mail': mail, 'firstname': firstname, 'lastname': lastname});
    return !response.data.error;
};

// returns an error of false if successfull
const singInWithMailToken = async (token) => {
    const functions = getFunctions();
    const verifyMailToken = httpsCallable(functions, 'verifyMailToken');
    const response = await verifyMailToken({token});
    const data = response.data;
    if(!data.token) {
      return data.error;
    }
    const auth = getAuth();
    await signInWithCustomToken(auth, data.token);
    window.localStorage.setItem('isAuthenticated', 'true');
    window.localStorage.setItem('user', JSON.stringify(data.user));
    window.location.href = "/";
    return false;
}

const checkEmailSyntax = (email) => {
  return email.toLowerCase().match(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i);
}

const hasTag = (tag) => {
  if(!IS_AUTHENTICATED) {
    return false;
  }
  if(!CURRENT_USER.tags) {
    return false;
  }
  return CURRENT_USER.tags.includes(tag);
}

if(!IS_AUTHENTICATED && !window.location.href.includes('/requestEmail') && !window.location.href.includes('/blank') && !window.location.href.includes('/activateAccount')) {
  window.location.href = '/requestEmail';
}

export { IS_AUTHENTICATED, CURRENT_USER, ALL_USER, ALL_ROLES, hasTag, fetchAllUser, requestAuthenticationUrl, singInWithMailToken, checkEmailSyntax };
