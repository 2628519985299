import {useNavigate} from "react-router-dom";

import * as auth from '../firebase/auth.js';
import {useEffect} from "react";
import {Box, CircularProgress} from "@mui/material";

function BlankPage() {
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const action = queryParameters.get('action');

  useEffect(() => {
      if(auth.IS_AUTHENTICATED) {
        navigate('/home/start');
        return;
      }
      if(action === 'signin') {
        auth.singInWithMailToken(queryParameters.get('token')).then(error => {
          if(!error) {
            window.location.href = '/';
          } else if(error === 'account inactive') {
            navigate('/activateAccount?retryToken=' + queryParameters.get('token'));
          } else {
            alert('Der Link ist ungültig oder zu alt. Bitte versuche erneut dich anzumelden!');
            navigate('/requestEmail');
          }
        });
      }
  }, []);

  return(
    <Box sx={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <CircularProgress size={80}/>
    </Box>
  );
}

export default BlankPage;
