import {SvgIcon} from "@mui/material";

function FencingIcon() {
  return (
    <SvgIcon>
      {/* credit: plus icon from https://heroicons.com/ */}
      <svg fill="currentColor" width="800px" height="800px" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.707,2.293a1,1,0,0,0-1.414,0L12,10.586,3.707,2.293A1,1,0,0,0,2.293,3.707L10.586,12,9.223,13.363a4.809,4.809,0,0,0-6.03.63,1,1,0,0,0,0,1.414L5.186,17.4,2.293,20.293a1,1,0,0,0,1.414,1.414L6.6,18.814l1.993,1.993a1,1,0,0,0,1.414,0,4.809,4.809,0,0,0,.63-6.03L12,13.414l1.363,1.363a4.809,4.809,0,0,0,.63,6.03,1,1,0,0,0,1.414,0L17.4,18.814l2.893,2.893a1,1,0,0,0,1.414-1.414L18.814,17.4l1.993-1.993a1,1,0,0,0,0-1.414,4.809,4.809,0,0,0-6.03-.63L13.414,12l8.293-8.293A1,1,0,0,0,21.707,2.293ZM9.171,18.557,5.443,14.829a2.877,2.877,0,0,1,3.15.578,2.824,2.824,0,0,1,.578,3.15Zm6.236-3.15a2.824,2.824,0,0,1,3.15-.578l-3.728,3.728a2.824,2.824,0,0,1,.578-3.15Z"/>
      </svg>
    </SvgIcon>
  )
    ;
}

export default FencingIcon;