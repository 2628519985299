import {initializeApp} from 'firebase/app';
import {getFunctions} from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBWw-HIdQOxEPCHA_nRLx62khNJ2WaZzgA",
  authDomain: "jslink-taw.firebaseapp.com",
  projectId: "jslink-taw",
  storageBucket: "jslink-taw.appspot.com",
  messagingSenderId: "987557499630",
  appId: "1:987557499630:web:294e5a741e44f5876519ff"
};

initializeApp(firebaseConfig);


const functions = getFunctions();
functions.region = 'europe-west3';
