import {useNavigate} from "react-router-dom";

import * as auth from '../../firebase/auth.js';
import {useEffect} from "react";
import {Box} from "@mui/material";
import FencingAdminTransferList from "../../modules/fencing/FencingAdminTransferList";
import FencingAdminAttendanceCalendar from "../../modules/fencing/FencingAdminAttendanceCalendar";

function FencingAdminPage() {
  const navigate = useNavigate();
  useEffect(() => {
    if(!auth.hasTag('fencing.admin')) {
      navigate('/');
    }
  }, []);

  return (
    <Box sx={{
          width: '100%',
          flexDirection: 'column',
          justifyContent: 'centre',
        }}>
      <Box sx={{
        fontSize: 25,
        fontWeight: 600,
        textAlign: 'center',
        marginBottom: '5px',
        marginTop: '10px',
      }}>
        Anwesenheit
      </Box>
      <FencingAdminAttendanceCalendar/>
      <Box sx={{
        fontSize: 25,
        fontWeight: 600,
        textAlign: 'center',
        marginBottom: '5px',
        marginTop: '-20px',
      }}>
        Paukpflichtig
      </Box>
      <FencingAdminTransferList/>
    </Box>
  );
}

export default FencingAdminPage;
